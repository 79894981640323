@font-face {
  font-family: 'SF Pro Text';
  src: url('styles/fonts/SFProText-Regular.eot');
  src: url('styles/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/SFProText-Regular.woff2') format('woff2'),
    url('styles/fonts/SFProText-Regular.woff') format('woff'),
    url('styles/fonts/SFProText-Regular.ttf') format('truetype'),
    url('styles/fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFMono-Medium';
  src: url('styles/fonts/SFMono-Medium.eot');
  src: url('styles/fonts/SFMono-Medium.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/SFMono-Medium.woff2') format('woff2'), url('styles/fonts/SFMono-Medium.woff') format('woff'),
    url('styles/fonts/SFMono-Medium.ttf') format('truetype'),
    url('styles/fonts/SFMono-Medium.svg#SFMono-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle-Regular';
  src: url('styles/fonts/Adelle-Regular.eot');
  src: url('styles/fonts/Adelle-Regular.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/Adelle-Regular.woff2') format('woff2'), url('styles/fonts/Adelle-Regular.woff') format('woff'),
    url('styles/fonts/Adelle-Regular.ttf') format('truetype'),
    url('styles/fonts/Adelle-Regular.svg#Adelle-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle-SemiBold';
  src: url('styles/fonts/Adelle-SemiBold.eot');
  src: url('styles/fonts/Adelle-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/Adelle-SemiBold.woff2') format('woff2'), url('styles/fonts/Adelle-SemiBold.woff') format('woff'),
    url('styles/fonts/Adelle-SemiBold.ttf') format('truetype'),
    url('styles/fonts/Adelle-SemiBold.svg#Adelle-SemiBold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('styles/fonts/Montserrat-Bold.eot');
  src: url('styles/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/Montserrat-Bold.woff2') format('woff2'), url('styles/fonts/Montserrat-Bold.woff') format('woff'),
    url('styles/fonts/Montserrat-Bold.ttf') format('truetype'),
    url('styles/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('styles/fonts/Montserrat-ExtraBold.eot');
  src: url('styles/fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('styles/fonts/Montserrat-ExtraBold.woff') format('woff'),
    url('styles/fonts/Montserrat-ExtraBold.ttf') format('truetype'),
    url('styles/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('styles/fonts/Montserrat-Medium.eot');
  src: url('styles/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('styles/fonts/Montserrat-Medium.woff') format('woff'),
    url('styles/fonts/Montserrat-Medium.ttf') format('truetype'),
    url('styles/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('styles/fonts/Montserrat-Regular.eot');
  src: url('styles/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('styles/fonts/Montserrat-Regular.woff') format('woff'),
    url('styles/fonts/Montserrat-Regular.ttf') format('truetype'),
    url('styles/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('styles/fonts/Montserrat-SemiBold.eot');
  src: url('styles/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('styles/fonts/Montserrat-SemiBold.woff') format('woff'),
    url('styles/fonts/Montserrat-SemiBold.ttf') format('truetype'),
    url('styles/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('styles/fonts/SFProText-Medium.eot');
  src: url('styles/fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/SFProText-Medium.woff2') format('woff2'), url('styles/fonts/SFProText-Medium.woff') format('woff'),
    url('styles/fonts/SFProText-Medium.ttf') format('truetype'),
    url('styles/fonts/SFProText-Medium.svg#SFProText-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('styles/fonts/SFProText-Semibold.eot');
  src: url('styles/fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/SFProText-Semibold.woff2') format('woff2'),
    url('styles/fonts/SFProText-Semibold.woff') format('woff'),
    url('styles/fonts/SFProText-Semibold.ttf') format('truetype'),
    url('styles/fonts/SFProText-Semibold.svg#SFProText-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('styles/fonts/SFProText-Bold.eot');
  src: url('styles/fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/SFProText-Bold.woff2') format('woff2'), url('styles/fonts/SFProText-Bold.woff') format('woff'),
    url('styles/fonts/SFProText-Bold.ttf') format('truetype'),
    url('styles/fonts/SFProText-Bold.svg#SFProText-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SFMono-Medium';
  src: url('styles/fonts/SFMono-Medium.eot');
  src: url('styles/fonts/SFMono-Medium.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/SFMono-Medium.woff2') format('woff2'), url('styles/fonts/SFMono-Medium.woff') format('woff'),
    url('styles/fonts/SFMono-Medium.ttf') format('truetype'),
    url('styles/fonts/SFMono-Medium.svg#SFMono-Medium') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SFMono-Light';
  src: url('styles/fonts/SFMono-Light.eot');
  src: url('styles/fonts/SFMono-Light.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/SFMono-Light.woff2') format('woff2'), url('styles/fonts/SFMono-Light.woff') format('woff'),
    url('styles/fonts/SFMono-Light.ttf') format('truetype'),
    url('styles/fonts/SFMono-Light.svg#SFMono-Light') format('svg');
  font-weight: bold;
  font-style: normal;
}
